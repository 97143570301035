import { computed, MaybeRefOrGetter, toValue } from "vue";
import { 
  DEFAULT_MAX_SIGNEDIN_ROADTRIPS,
  Profile,
} from '../models/profile';
import { Roadtrip } from "../models/roadtrip";
import { useServices } from "./services";
import { UserState } from "../userState";

export function useSubscriptionFunnel(userState: MaybeRefOrGetter<UserState>, profile: MaybeRefOrGetter<Profile | undefined>, roadtrips: MaybeRefOrGetter<Array<Roadtrip>>) {

  const { roadtripsService, profilesService } = useServices();
  
  const mustSignUpForMoreRoadtrips = computed(() => {
    if(!toValue(userState).isSignedIn) return false; // Only show this if they're signed in ANONYMOUSLY
    if(!toValue(userState).isAnonymous) return false; // Already signed in non-anonymously, signing in won't do any good.
    if(!toValue(roadtrips).length) return false; // They haven't created the first road trip yet, they don't need to sign in
    return true;
  });
  
  const mustSubscribeForMoreRoadtrips = computed(() => {
    if(toValue(userState).loading) return false; // Don't need to encourage them to sign in if they're in the midst of auth action
    if(toValue(userState).isAnonymous) return false; // They're anonymous, they CAN'T subscribe; so don't tell them to.
    if(!toValue(userState).isSignedIn) return false; // They're not signed in, they CAN'T subscribe; so don't tell them to
    if(profilesService.isLoading.value) return false; // Loading; can't decide yet if they need to subscribe to create more roadtrips 
    if(profilesService.hasSubscription.value) return false; // They already have a subscription, subscribing again won't help them.
    if(!toValue(profile)) return false; // No profile, so they have to sign up before they can subscribe (for more road trips)
    if(roadtripsService.isLoading.value) return false; // We don't know what to compare against (yet)
    // Similarly to the above sanity check, this SHOULDN'T happen, by design, but if it does, deal with it ratioanlly
    if(!toValue(roadtrips)) { console.warn('Roadtrips are loaded, but empty - no way to determine how many we have.'); return false; }
  
    const maxAllowedRoadtrips = toValue(profile)!.maxAllowedRoadtrips || DEFAULT_MAX_SIGNEDIN_ROADTRIPS;
    return (toValue(roadtrips).length >= maxAllowedRoadtrips);
  });
  
  return { 
    mustSignUpForMoreRoadtrips,
    mustSubscribeForMoreRoadtrips,
  }
};
